import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const MenuLeft = ({ path }) => {
  return (
    <Menu>
      <List>
        <ElemStyle>
          {path !== "/who-we-are/" ? (
            <Link
              to={"/who-we-are/"}
              className={` ${
                path === "/who-we-are/"
                  ? "active"
                  : path === "/"
                  ? "white"
                  : "link"
              }`}
            >
              <ListItem path={path}>
                <span>Who we are</span>
              </ListItem>
            </Link>
          ) : (
            <ListItem
              className={` ${
                path === "/who-we-are/"
                  ? "active"
                  : path === "/"
                  ? "white"
                  : "link"
              }`}
              path={path}
            >
              <span>Who we are</span>
            </ListItem>
          )}
        </ElemStyle>
        <ElemStyle>
          {path !== "/what-we-build/" ? (
            <Link
              to={"/what-we-build/"}
              className={` ${
                path === "/what-we-build/"
                  ? "active"
                  : path === "/"
                  ? "white"
                  : "link"
              }`}
            >
              <ListItem path={path}>
                <span>What we build</span>
              </ListItem>
            </Link>
          ) : (
            <ListItem
              className={` ${
                path === "/what-we-build/"
                  ? "active"
                  : path === "/"
                  ? "white"
                  : "link"
              }`}
              path={path}
            >
              <span>What we build</span>
            </ListItem>
          )}
        </ElemStyle>
        <ElemStyle>
          {path !== "/sectors/" ? (
            <Link
              to={"/sectors/"}
              className={`${
                path === "/sectors/"
                  ? "active"
                  : path === "/sector/healthtech"
                  ? "active"
                  : path === "/sector/biotech"
                  ? "active"
                  : path === "/sector/fintech"
                  ? "active"
                  : path === "/sector/power-management"
                  ? "active"
                  : path === "/"
                  ? "white"
                  : "link"
              }`}
            >
              <ListItem path={path}>
                <span>Where we focus</span>
              </ListItem>
            </Link>
          ) : (
            <ListItem
              className={` ${
                path === "/sectors/"
                  ? "active"
                  : path === "/"
                  ? "white"
                  : "link"
              }`}
              path={path}
            >
              <span>Where we focus</span>
            </ListItem>
          )}
        </ElemStyle>
        <ElemStyle>
          {path !== "/contact-us/" ? (
            <Link
              to={"/contact-us/"}
              className={` ${
                path === "/contact-us/"
                  ? "active"
                  : path === "/"
                  ? "white"
                  : "link"
              }`}
            >
              <ListItem path={path}>
                <span>How to connect</span>
              </ListItem>
            </Link>
          ) : (
            <ListItem
              className={` ${
                path === "/contact-us/"
                  ? "active"
                  : path === "/"
                  ? "white"
                  : "link"
              }`}
              path={path}
            >
              <span>How to connect</span>
            </ListItem>
          )}
        </ElemStyle>
      </List>
    </Menu>
  );
};

const List = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  height: 40vh;
  justify-content: flex-end;
  list-style-type: none;
`;

const ElemStyle = styled.li`
  .link {
    color: #ffffff;
    opacity: 0.6;
    div {
      color: #ffffff;
      opacity: 0.6;
    }
  }

  .white {
    opacity: 1;
    div {
      opacity: 1;
    }
  }

  .active {
    opacity: 1;
    div {
      opacity: 1;
    }
    // padding: 1.5rem 0;
    color: ${({ theme }) => theme.colors.white};
    position: relative;
    // width: fit-content;

    span:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -10px;
      left: 0;
      background: ${({ theme }) => theme.colors.secondary};
      visibility: visible;
      transform: scaleX(1);
      transition: 0.25s linear;
    }
  }
`;

const ListItem = styled.div`
  width: 100%;
  height: 6rem;
  position: relative;
  overflow: hidden;
  span {
    animation: ${({ path }) => (path === "/" ? "fadeinHomeText 2.8s" : "none")};
    -moz-animation: ${({ path }) =>
      path === "/" ? "fadeinHomeText 2.8s" : "none"}; /* Firefox */
    -webkit-animation: ${({ path }) =>
      path === "/" ? "fadeinHomeText 2.8s" : "none"}; /* Safari and Chrome */
    -o-animation: ${({ path }) =>
      path === "/" ? "fadeinHomeText 2.8s" : "none"}; /* Opera */
    position: absolute;
    display: inline-block;
  }

  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-transform: capitalize;
  // padding: 1.5rem 0;
  color: ${({ theme }) => theme.colors.white};
  position: relative;

  span:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -10px;
    left: 0;
    background: ${({ theme }) => theme.colors.secondary};
    visibility: hidden;
    transform: scaleX(0);
    transition: 0.25s linear;
  }

  span:hover:before,
  span:focus:before {
    visibility: visible;
    transform: scaleX(1);
  }

  @media ${({ theme }) => theme.breakpoints.small_height} {
    font-size: 15px;
    line-height: 10px;
  }
`;
const Menu = styled.div``;

export default MenuLeft;
