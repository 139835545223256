import React from 'react'
import styled from 'styled-components'
import {graphql} from "gatsby";

import bg from '../../../../static/images/sectors-page/hero/images/bg.svg'
import logo from '../../../../static/images/sectors-page/hero/images/logo.svg'
import MenuLeft from "../../menu-left/MenuLeft.js";

const Hero = () => {

    return (
        <>
            <HeroStyleMobile>
                <h2>
                    STAY UPDATED
                </h2>
            </HeroStyleMobile>
            <HeroStyle>
                <MenuLeft />
                <Title>
                    <h2>
                        STAY UPDATED
                    </h2>
                </Title>
            </HeroStyle>
        </>
    )
}

const HeroStyleMobile = styled.div`
  padding-top: 6rem;
  padding-left: 2.4rem;
  min-height: calc(100vh);
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  @media ${({ theme }) => theme.breakpoints.mobile_reverse} {
    display: none;
  }
  
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: ${({theme}) => theme.colors.white};
  
`

const HeroStyle = styled.div`
  padding: 10rem 12rem 6rem 12rem;
  min-height: calc(100vh);
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  color: ${({theme}) => theme.colors.white};
  justify-content: space-between;
  @media ${({theme}) => theme.breakpoints.mobile} {
    display: none !important;
    justify-content: center;
  }
  
`

const Title = styled.div`
  align-self: flex-end;  
  @media ${({ theme }) => theme.breakpoints.mobile} {
      align-self: center; 
      h2 {
        font-size: 2.6rem;
        line-height: 3.6rem;
        text-align: center;
      } 
  }
`

export default Hero

const query = graphql`
    query {
        strapiStayUpdatedPage {
            title
            description
        }
    }
`;
