import { graphql, useStaticQuery } from "gatsby";
import React from 'react'

import Hero from "../components/stay-updated-page/hero/Hero.js";
import Layout from '../components/layout/Layout'
import Seo from '../components/seo'
import StayUpdated from "../components/stay-updated/StayUpdated.js";

const StayUpdatedPage = (props) => {
    const {strapiStayUpdatedPage} = useStaticQuery(query);

    return (
        <Layout path={props.location.pathname}>
            <Seo
                title={strapiStayUpdatedPage.metaTitle}
                description={strapiStayUpdatedPage.metaDescription}
                keywords={strapiStayUpdatedPage.metaKeywords}
            />
            <Hero/>
            <StayUpdated />
        </Layout>
    )
}

export default StayUpdatedPage

const query = graphql`
    query {
        strapiStayUpdatedPage {
            metaTitle
            metaDescription
            metaKeywords
        }
    }
`
